import * as React from 'react';
import theme from '../../util/theme';
import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { VictoryBar, VictoryChart, VictoryLabel, VictoryAxis } from 'victory';
import { desktopSize, laptopSize, mobileSize } from '../../util/variables';
import Img from 'gatsby-image';
import { MapTooltip } from '../ui';
import { graphql, useStaticQuery } from 'gatsby';

interface IWorldMapProps {
  num_american_companies: number;
  num_european_companies: number;
  num_other_companies: number;
  american_company_images?: string[];
  european_company_images?: string[];
  other_company_images?: string[];
}

/**
 * Creates a map with labels on it.
 *
 */
const WorldMap: React.FunctionComponent<IWorldMapProps> = ({
  num_american_companies,
  num_european_companies,
  num_other_companies,
  american_company_images,
  european_company_images,
  other_company_images,
}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/map/shopify-map.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Div>
      <FilterHeadingLabel>
        Top Companies around the world{' '}
        <SpanContainer
          style={{ marginTop: '4px' }}
          data-tip="Logos represented are the largest or fastest growing companies by region. The number represents total companies identified by Base10 in the region."
        >
          <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />{' '}
        </SpanContainer>
      </FilterHeadingLabel>
      <MapDiv>
        <MapContainer>
          <Img fluid={data.file.childImageSharp.fluid} style={{ opacity: 0.4, zIndex: 0 }} />
          {/* <CanadaToolTip>
            <MapTooltip numberText={num_canadian_companies}>
              <WrapImages>
                {!!canadian_company_images &&
                  canadian_company_images.length &&
                  canadian_company_images.map(company => (
                    <CompanyImage key={company} width="50px" src={company} />
                  ))}
              </WrapImages>
            </MapTooltip>
          </CanadaToolTip> */}
          <AmericaToolTip>
            <MapTooltip numberText={num_american_companies}>
              <WrapImages>
                {!!american_company_images &&
                  american_company_images.length &&
                  american_company_images.map(company => (
                    <CompanyImage
                      key={company}
                      // width="50px"
                      src={require(`../../content/img/research/logos/${company}.jpg`)}
                    />
                  ))}
              </WrapImages>
            </MapTooltip>
          </AmericaToolTip>
          <EUToolTip>
            <MapTooltip numberText={num_european_companies}>
              <WrapImages>
                {!!european_company_images &&
                  european_company_images.length &&
                  european_company_images.map(company => (
                    <CompanyImage
                      key={company}
                      // width="50px"
                      src={require(`../../content/img/research/logos/${company}.jpg`)}
                    />
                  ))}
              </WrapImages>
            </MapTooltip>
          </EUToolTip>
          <OthersToolTip>
            <MapTooltip numberText={num_other_companies}>
              <WrapImages>
                {!!other_company_images &&
                  other_company_images.length &&
                  other_company_images.map(company => (
                    <CompanyImage
                      key={company}
                      // width="50px"
                      src={require(`../../content/img/research/logos/${company}.jpg`)}
                    />
                  ))}
              </WrapImages>
            </MapTooltip>
          </OthersToolTip>
        </MapContainer>
      </MapDiv>
    </Div>
  );
};

const Div = styled.div``;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

const MapDiv = styled.div`
  /* height: 500px;
  width: 500px; */
  position: relative;
  max-width: 90vw;
  max-height: 350px;
  min-height: 280px;
  margin: 20px;
  z-index: 1;
  padding: 0;
  @media ${desktopSize} {
    max-width: 50vw;
  }
  @media ${laptopSize} {
    min-height: auto;
    margin: 0;
    max-width: 90vw;
  }
  @media ${mobileSize} {
    margin: 0;
    max-width: 90vw;
    margin-bottom: 20px;
  }
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
`;

const WrapImages = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;

const CompanyImage = styled.img`
  max-height: 16px;
  max-width: 50px;
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const CanadaToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 1;
  top: 5%;
  left: 17%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

const AmericaToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 2;
  top: 45%;
  left: 17%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

const EUToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 3;
  top: 15%;
  right: 15%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

const OthersToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 3;
  bottom: 7%;
  right: 5%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

export { WorldMap };
