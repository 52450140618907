import React, { FunctionComponent, useState, useEffect, useMemo, useReducer } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { mobileSize, laptopSize, desktopSize } from '../../../util/variables';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AnimatedLinkAnchor, AppText, AppTitle, AnimatedSpan } from '../../ui';
import ReactTooltip from 'react-tooltip';
import { useStaticQuery, graphql } from 'gatsby';
import { gql, useMutation } from '@apollo/client';
import { TrendMapModal } from './TrendMapModal';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { GraphsComponent } from './GraphsComponent';
import { key_insights } from './key_insights';
import Axios from 'axios';
import {
  extractFloat,
  extractFloatAndRoundToBillion,
  extractFloatAndConditionallyRoundToBillion,
} from '../../../util/numbers';
import { Histogram, WorldMap, HorizontalBar } from '../../graphs';
import { tryRequire } from '../../../util/misc';
import { linkedin_urls } from '../../../static/linkedin_urls';
import graduationCap from '../../../content/img/research/graduation-cap.png';
import someCompanies from '../../../content/img/research/companies.png';
import { DisclaimerPopupComponent } from './DisclaimerPopup';
import { useImageList } from '../../../content/img/research/imagesList';

interface Props {
  data?: any;
  hbcu?: boolean;
}

// const SHEETS_URL = 'https://sheet.best/api/sheets/10cf05d8-8cbb-4b1a-8b90-b0cee1ddb9bc';
const SHEETS_URL = 'https://sheet.best/api/sheets/06be8750-0615-42ee-889f-a329892200e2';

const listColors = ['#45aeeb', '#3586b5', '#8bd5ff'];

const Research2Segment: FunctionComponent<Props> = ({ hbcu }) => {
  const POST_MUTATION = gql`
    mutation creatingMarketingEmail($email: String!, $source: String!) {
      createOneMarketingEmail(data: { email: $email, source: $source }) {
        id
        email
      }
    }
  `;

  const [addEmailData, { emailData }] = useMutation(POST_MUTATION);

  const [showMore, setShowMore] = useState([]);
  const [guestEmail, setGuestEmail] = useState('');
  const [showModal, setShowModal] = useState(!hbcu);
  const [mapData, setMapData] = useState([]);
  const [tableData, setTableData] = useState<any>([]);
  const [filterData, setFilterData] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [valuationHistogram, setValuationHistogram] = useState([]);
  const [exitHistogram, setExitHistogram] = useState([]);
  const [targetInvestmentAllocation, setTargetInvestmentAllocation] = useState([]);
  const [filter, setFilter] = useState<any>({});
  const [tableTooltipsData, setTableTooltipsData] = useState([]);

  /**
   * Fetch data from Google Sheets
   */
  useEffect(() => {
    const fetchTableData = async () => {
      const tableDataResponse = await Axios.get(`${SHEETS_URL}/tabs/Table`);
      setTableData(tableDataResponse.data);
    };

    const fetchMapData = async () => {
      const mapDataResponse = await Axios.get(`${SHEETS_URL}/tabs/Chart - HQ Map`);
      setMapData(mapDataResponse.data);
    };

    const fetchFilterData = async () => {
      const filterDataResponse = await Axios.get(`${SHEETS_URL}/tabs/Table Filters`);
      setFilterData(filterDataResponse.data);
    };

    const fetchFundingData = async () => {
      const fundingDataResponse = await Axios.get(`${SHEETS_URL}/tabs/Chart - Funding by year`);
      setFundingData(fundingDataResponse.data);
    };

    const fetchValuationData = async () => {
      const valuationHistogramResponse = await Axios.get(
        `${SHEETS_URL}/tabs/Chart - Valuation Histogram`,
      );
      setValuationHistogram(valuationHistogramResponse.data);
    };

    const fetchExitData = async () => {
      const exitHistogramResponse = await Axios.get(
        `${SHEETS_URL}/tabs/Chart - Expected Exit Histogram`,
      );
      setExitHistogram(exitHistogramResponse.data);
    };

    const fetchTargetInvestmentAllocation = async () => {
      const targetInvestmentAllocation = await Axios.get(
        `${SHEETS_URL}/tabs/Chart - Target Allocation`,
      );
      setTargetInvestmentAllocation(targetInvestmentAllocation.data);
    };

    const fetchTooltipsData = async () => {
      const tooltipsResponse = await Axios.get(`${SHEETS_URL}/tabs/segment_detail`);
      setTableTooltipsData(tooltipsResponse.data);
    };

    fetchTableData();
    fetchMapData();
    fetchFilterData();
    fetchFundingData();
    fetchValuationData();
    fetchExitData();
    fetchTargetInvestmentAllocation();
    fetchTooltipsData();
  }, []);
  console.log('tableData: ', tableData);
  console.log('mapData: ', mapData);
  console.log('fundData: ', fundingData);
  console.log('filterData: ', filterData);
  console.log('valuationHistogram: ', valuationHistogram);
  console.log('exitHistogram: ', exitHistogram);
  console.log('targetInvestmentAllocation: ', targetInvestmentAllocation);

  const toggleModal = () => {
    trackCustomEvent({
      action: 'Click',
      category: 'Footnote',
      label: `Companies map modal ${showModal ? 'close' : 'open'}`,
    });
    setShowModal(!showModal);
  };

  const section_titles = useMemo(
    () =>
      tableData.reduce(
        (acc: any[], cv: any) => (acc.includes(cv.Segment) ? acc : [...acc, cv.Segment]),
        [] as any[],
      ),
    [tableData],
  );

  /**
   * Filter function
   */
  const filteredCompanies = tableData
    .map(row => {
      return {
        ...row,
        'Last Valuation': extractFloat(row['Last Valuation']),
        'Total Raised': extractFloat(row['Total Raised']),
        'Revenue*': extractFloatAndConditionallyRoundToBillion(row['Revenue*']),
      };
    })
    .filter(row => {
      // if there is an key in filters state, that filter will adjust the data
      // For each row, check to see if there is an applicable filter. If not, return true
      const ret = Object.keys(row).map(rowKey => {
        if (filter[rowKey]) {
          if (filter[rowKey].value === 'All') {
            return true;
          }

          // Perform filter
          // Need to account for different filter types
          switch (filter[rowKey]['Filter Type']) {
            case 'Select':
              if (!row[rowKey].includes(filter[rowKey].value)) {
                return false;
              }

            case 'Bin':
              const [min, max] = filter[rowKey].value.split(':');
              if (row[rowKey] < min || row[rowKey] > max) {
                return false;
              }

            default:
              return true;
          }
        }

        return true;
      });
      return ret.filter(r => Boolean(r)).length === Object.keys(row).length;
    });

  const MoreButton: JSX.Element = (props: any) => {
    return (
      <MoreButtonStyle
        onClick={() => {
          trackCustomEvent({
            action: 'Click',
            category: `Table: ${props.table}`,
            label: `${showMore.includes(props.id) ? 'less' : 'more'} click on ${props.column}`,
          });
          showMore.includes(props.id)
            ? setShowMore(showMore.filter(i => i !== props.id))
            : setShowMore(showMore.concat(props.id));
        }}
      >
        {showMore.includes(props.id) ? 'less' : 'more'}
      </MoreButtonStyle>
    );
  };

  const findTooltipForSegment = (segment: string) => {
    const tooltip = tableTooltipsData
      .map((item: any) => item)
      .filter((item: any) => item.segment === segment);
    if (!!tooltip) return tooltip[0]?.detail;
    return false;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tableData]);

  return (
    <ShopifyInsightsContainer>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: 'center',
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor="rgba(0,0,0,0.9)"
      />
      {showModal && (
        <DisclaimerPopupComponent showModal={showModal} toggleModal={() => setShowModal(false)} />
      )}
      {/* <TrendMapModal toggleModal={toggleModal} showModal={showModal} /> */}
      {/* <SummaryTitle>
        <SummaryTitleText>Summary of</SummaryTitleText>
        &nbsp;
        <SummaryTitleText bold>the Advancement Initiative</SummaryTitleText>
      </SummaryTitle> */}
      <KeyInsightsListTitle style={{ fontWeight: 'bold' }}>
        Advancement Fund Initiative - Portfolio &amp; Universe Construction
      </KeyInsightsListTitle>
      {/* <KeyInsightsListItems>
        <ol>
          <li>
            Base10 is the leading, <BlueSpan>largest Black-led venture capital firm</BlueSpan>,
            started by former entrepreneurs and investors:
            <ThreePartsDiv>
              <FirstPointDiv>
                <FirstPointLabel>Founded:</FirstPointLabel>
                <FirstPointNumber>2017</FirstPointNumber>
              </FirstPointDiv>
              <FirstPointDiv>
                <FirstPointLabel>AUM:</FirstPointLabel>
                <FirstPointNumber>$450M+</FirstPointNumber>
              </FirstPointDiv>
              <FirstPointDiv>
                <FirstPointLabel>Investments:</FirstPointLabel>
                <FirstPointNumber>42</FirstPointNumber>
              </FirstPointDiv>
            </ThreePartsDiv>
          </li>
          <li>
            Following our <BlueSpan>Diversity Pledge</BlueSpan>, Base10 received inbound interest
            from several of the fastest growing pre-IPO companies (Instacart, Nubank, Stripe)
            seeking to <BlueSpan>partner with us on diversity & inclusion efforts</BlueSpan>
          </li>
          <li>
            Base10 is launching the <BlueSpan>$350M Advancement Initiative</BlueSpan> (the “Fund”)
            which will invest in these top pre-IPO companies and donate 20% of its profits to
            Historically Black Colleges & Universities
          </li>
          <li>
            <BlueSpan>This Fund is an unfair advantage</BlueSpan>: these companies are inaccessible
            but will work with Base10 at a discount to market prices because we help them
            significantly expand and advance their D&I efforts
          </li>
          <li>
            <BlueSpan>Cambridge Associates</BlueSpan> (existing Base10 Anchor LP) and{' '}
            <BlueSpan>Howard University</BlueSpan> (largest HBCU) have committed to anchor the fund
          </li> */}
      {/* <li>
            Base10 is launching the <BlueSpan>$350M HBCU Advancement Fund</BlueSpan> (the “Fund”) to
            invest in the <BlueSpan>best pre-IPO technology companies</BlueSpan> and donate 20% of
            the Fund’s profits to <BlueSpan>Historically Black Colleges & Universities </BlueSpan>
            (HBCUs)
          </li>
          <li>
            Base10 is offering{' '}
            <BlueSpan>companies that participate in the Advancement Fund</BlueSpan> the opportunity
            to:
            <ThreePartsDiv>
              <ThirdPointDiv>
                <ThirdPointHeader>
                  <ThirdPointHeaderText>
                    ADVANCE D&I INITITIVES BY ALIGNING WITH HBCU’s
                  </ThirdPointHeaderText>
                </ThirdPointHeader>
                <ThirdPointTwoSegments>
                  <div>
                    <ThirdPointNormalText>HBCUs account for only</ThirdPointNormalText>
                    <ThirdPointBigBlueText>3%</ThirdPointBigBlueText>
                    <ThirdPointBlueText>of colleges anduniversities</ThirdPointBlueText>
                  </div>
                  <div>
                    <ThirdPointNormalText>but produce</ThirdPointNormalText>
                    <ThirdPointBigBlueText>60%</ThirdPointBigBlueText>
                    <ThirdPointBlueText>of all African American PhDs</ThirdPointBlueText>
                  </div>
                </ThirdPointTwoSegments>
              </ThirdPointDiv>
              <ThirdPointDiv>
                <ThirdPointHeader>
                  <ThirdPointHeaderText>RECRUIT THE BEST DIVERSE TALENT</ThirdPointHeaderText>
                </ThirdPointHeader>
                <ThirdPointTwoSegments style={{ margin: '10px 0px' }}>
                  <div>
                    <ThirdPointNormalText style={{ margin: '0px' }}>
                      Access to Base10’s
                    </ThirdPointNormalText>
                    <ThirdPointNormalText style={{ margin: '0px' }}>
                      {' '}
                      HBCU Talent Program
                    </ThirdPointNormalText>
                  </div>
                  <img src={graduationCap} alt="Graduation cap" height="40px" />
                </ThirdPointTwoSegments>
                <ThirdPointTwoSegments>
                  <div>
                    <ThirdPointBigBlueText>2,500</ThirdPointBigBlueText>
                    <ThirdPointBlueText>talented students and interns</ThirdPointBlueText>
                  </div>
                  <div>
                    <ThirdPointBigBlueText>20</ThirdPointBigBlueText>
                    <ThirdPointBlueText>HBCUs represented</ThirdPointBlueText>
                  </div>
                </ThirdPointTwoSegments>
              </ThirdPointDiv>
              <ThirdPointDiv>
                <ThirdPointHeader>
                  <ThirdPointHeaderText>
                    REALIZE AN ATTRACTIVE RETURN ON YOUR INVESTMENT{' '}
                    <span style={{ fontSize: '10px', verticalAlign: 'top' }}>[1]</span>
                  </ThirdPointHeaderText>
                </ThirdPointHeader>
                <ThirdPointTwoSegments>
                  <div>
                    <ThirdPointBigBlueText>5x</ThirdPointBigBlueText>
                    <ThirdPointNormalText>Return Potential</ThirdPointNormalText>
                  </div>
                  <div>
                    <ThirdPointBigBlueText>3 years</ThirdPointBigBlueText>
                    <ThirdPointNormalText>Time to Liquidity</ThirdPointNormalText>
                  </div>
                </ThirdPointTwoSegments>
                <ThirdPointNormalText style={{ marginBottom: '10px' }}>
                  Investing in the best pre-IPO companies:
                </ThirdPointNormalText>
                <img src={someCompanies} alt="List of some companies" width="280px" />
              </ThirdPointDiv>
            </ThreePartsDiv>
          </li> */}
      {/* </ol>
      </KeyInsightsListItems> */}
      <AdvancementTitle>Model Portfolio Construction</AdvancementTitle>
      <AdvancementText style={{ marginBottom: '15px' }}>
        Our portfolio construction and diversification creates access to top private companies while
        reducing market timing risk. Below is a model portfolio of what we would have built in 2018
        using this approach. This portfolio in 2018 could have returned up to 25x*.
      </AdvancementText>
      <DisclaimerText style={{ marginBottom: '40px' }}>
        * The 2018 portfolio comprises 15 of the top pre-IPO companies in 2018 that have since all
        gone public. Our analysis assumes the Fund would have invested in these companies in 2018 at
        their most recent private valuations at that time.
      </DisclaimerText>
      <GraphsDiv>
        <div>
          {targetInvestmentAllocation?.length && (
            <HorizontalBar
              data={targetInvestmentAllocation
                .map((v: any) => ({ ...v, '% of Fund': parseInt(v['% of Fund']) }))
                .sort((a, b) => a - b)}
              title="2018 Model Portfolio"
              xAxis={Object.keys(targetInvestmentAllocation[0])[0]}
              yAxis={Object.keys(targetInvestmentAllocation[0])[1]}
              yLabel="% of Fund"
              xLabel="Company"
            />
          )}
        </div>
        <SummaryStatsDiv>
          <SummaryStatsTitle>2018 Model Portfolio Performance</SummaryStatsTitle>
          <SummaryStatsNumber>25x Return</SummaryStatsNumber>

          <SummaryStatsTitle>Total Companies</SummaryStatsTitle>
          <SummaryStatsNumber>~15 Investments</SummaryStatsNumber>

          <SummaryStatsTitle>Individual Investment</SummaryStatsTitle>
          <SummaryStatsNumber>Max 10% of Fund</SummaryStatsNumber>

          <SummaryStatsTitle>30% of Fund in Market Leaders</SummaryStatsTitle>
          <SummaryStatsNumber>Returning 3-5x</SummaryStatsNumber>

          <SummaryStatsTitle>70% of Fund in Emerging Market Leaders</SummaryStatsTitle>
          <SummaryStatsNumber>Returning &gt;5x</SummaryStatsNumber>

          <SummaryStatsTitle>~30% of Investments in</SummaryStatsTitle>
          <SummaryStatsNumber>Profitable Companies</SummaryStatsNumber>

          <SummaryStatsTitle>Liquidity Profiles of 1-4 Years Reduces</SummaryStatsTitle>
          <SummaryStatsNumber>Market Timing Risk</SummaryStatsNumber>
        </SummaryStatsDiv>
      </GraphsDiv>
      {/* <GraphsComponent
        amountOfFunding={data.allAmountOfFundingJson.nodes}
        cumulativeRaised={data.allCumulativeRaisedJson.nodes}
        circleNumbers={data.allCircleNumbersJson.nodes}
        numberOfUnicorns={data.allNumberOfUnicornsJson.nodes}
      /> */}
      {/* <GraphsDiv>
        <div>
          <WorldMap
            num_american_companies={
              mapData.filter((a, b) => a['Region'] === 'United States').length
            }
            num_european_companies={mapData.filter((a, b) => a['Region'] === 'Europe').length}
            num_other_companies={
              mapData.filter((a, b) => a['Region'] !== 'United States' && a['Region'] !== 'Europe')
                .length
            }
            american_company_images={mapData
              .filter(n => n['Region'] === 'United States')
              .map((m: any) => m.Company)
              .slice(0, 3)}
            european_company_images={mapData
              .filter(n => n['Region'] === 'Europe')
              .map((m: any) => m.Company)
              .slice(0, 3)}
            other_company_images={mapData
              .filter(n => n['Region'] !== 'United States' && n['Region'] !== 'Europe')
              .map((m: any) => m.Company)
              .slice(0, 3)}
          />
        </div>
        <div>
          {valuationHistogram?.length && (
            <Histogram
              data={valuationHistogram
                .map((v: any) => ({ ...v, Count: parseInt(v.Count) }))
                .sort((a, b) => a.$ - b.$)}
              title="Current Valuations"
              xAxis={Object.keys(valuationHistogram[0])[0]}
              yAxis={Object.keys(valuationHistogram[0])[2]}
              xLabel="Valuation range ($B)"
              yLabel="Number of companies"
            />
          )}
          {exitHistogram?.length && (
            <Histogram
              data={exitHistogram
                .map((v: any) => ({ ...v, Funding: parseInt(v.Funding) }))
                .sort((a, b) => a.Year - b.Year)}
              title="Amount of Funding"
              xAxis={Object.keys(exitHistogram[0])[0]}
              yAxis={Object.keys(exitHistogram[0])[1]}
              xLabel="Year"
              yLabel="Number of companies"
              color="#f1ca0c"
            />
          )} */}
      {/* {fundingData && fundingData.length && (
            <Histogram
              data={fundingData
                .map((v: any) => ({ ...v, Funding: extractFloatAndRoundToBillion(v.Funding) }))
                .sort((a, b) => a['Year'] - b['Year'])}
              title="Funding since 2007"
              xAxis={Object.keys(fundingData[0])[0]}
              yAxis={Object.keys(fundingData[0])[1]}
              color="#9e5fd7"
              labelUnit="B"
            />
          )} */}
      {/* </div>
      </GraphsDiv> */}
      <AdvancementTitle>Universe Detail</AdvancementTitle>
      <AdvancementText>
        Base10 is conducting thorough due diligence to identify an investable universe of companies
        that fit our investment strategy and compare favorably to the top IPOs of the last several
        years.
      </AdvancementText>

      <FiltersList>
        <FilterHeadingLabel>{filteredCompanies.length} Companies</FilterHeadingLabel>
        <FilterByLabel>Filter by</FilterByLabel>
        {filterData.map(f => {
          if (f['Filter Type'] === 'Select') {
            return (
              <FitlerSelect
                name={f.Field}
                id={f.Field}
                onChange={e => {
                  trackCustomEvent({
                    action: 'Click',
                    category: 'Filter',
                    label: `${f.Field} filter change to ${e.target.value}`,
                  });
                  setFilter({
                    ...filter,
                    [f.Field]: { 'Filter Type': 'Select', value: e.target.value },
                  });
                }}
              >
                <option value="All">{f.Field}</option>
                {tableData
                  .reduce((acc, cv) => {
                    if (f.Field === 'Key Investors') {
                      return [...acc, ...cv[f.Field].split(',').map(c => c.trim())];
                    }

                    return [...acc, cv[f.Field]];
                  }, [])
                  .reduce((acc, cv) => (acc.includes(cv) ? acc : [...acc, cv]), [])
                  .filter(item => Boolean(item))
                  .sort()
                  .map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </FitlerSelect>
            );
          }

          return (
            <FitlerSelect
              name={f.Field}
              id={f.Field}
              onChange={e => {
                trackCustomEvent({
                  action: 'Click',
                  category: 'Filter',
                  label: `${f.Field} filter change to ${e.target.value}`,
                });
                setFilter({
                  ...filter,
                  [f.Field]: { 'Filter Type': 'Bin', value: e.target.value },
                });
              }}
            >
              <option value="All">{f.Field}</option>
              {tableData
                .reduce((acc, cv) => {
                  if (acc.length) {
                    return acc;
                  }

                  console.log(
                    'td: ',
                    tableData.map(td => td[f.Field]),
                  );
                  // Get max of field
                  const max = Math.round(
                    Math.max.apply(
                      Math,
                      tableData.map(td => extractFloat(td[f.Field])),
                    ),
                  );
                  const min = Math.round(
                    Math.min.apply(
                      Math,
                      tableData.map(td => extractFloat(td[f.Field])),
                    ),
                  );
                  const increment = Math.round((max - min) / 5);
                  const ret = [
                    {
                      label: `$${min}B - $${min + increment}B`,
                      value: `${min}:${min + increment}`,
                    },
                    {
                      label: `$${min + (increment + 1)}B - $${min + increment * 2}B`,
                      value: `${min + (increment + 1)}:${min + increment * 2}`,
                    },
                    {
                      label: `$${min + (increment * 2 + 1)}B - $${min + increment * 3}B`,
                      value: `${min + (increment * 2 + 1)}:${min + increment * 3}`,
                    },
                    {
                      label: `$${min + (increment * 3 + 1)}B - $${min + increment * 4}B`,
                      value: `${min + (increment * 3 + 1)}:${min + increment * 4}`,
                    },
                    {
                      label: `$${min + (increment * 4 + 1)}B - $${max}B`,
                      value: `${min + (increment * 3 + 1)}:${max}`,
                    },
                  ];
                  // Get min of field
                  // Divide range by 5
                  // Create 5 ranges
                  const ret2 = [
                    {
                      label: 'Emerging Leaders (<$5B EV)',
                      value: `${min}:5`,
                    },
                    {
                      label: 'Established Leaders (>$5B EV)',
                      value: `5:${max}`,
                    },
                  ];
                  return ret2;
                }, [])
                .sort()
                .map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </FitlerSelect>
          );
        })}
      </FiltersList>
      <ResearchInvestorsFullDiv>
        <ResearchCompaniesDiv>
          <table>
            <thead>
              {Boolean(tableData) && Boolean(tableData.length) && (
                <tr>
                  {Object.keys(tableData[0]).map((h, i) => {
                    if (i === 0) {
                      return <th style={{ position: 'sticky', left: 0, zIndex: 4 }}>{h}</th>;
                    }

                    if (i === 1) {
                      return <th id="secondColHead">{h}</th>;
                    }

                    return <th>{h}</th>;
                  })}
                </tr>
              )}
            </thead>
            {filteredCompanies.length ? (
              section_titles.map(item => (
                <tbody key={item}>
                  {Boolean(filteredCompanies.filter(c => c.Segment === item).length) && (
                    <tr>
                      <td colSpan={3} style={{ position: 'sticky', left: '0' }}>
                        <TableSubTitle>
                          {item}
                          {findTooltipForSegment(item) && (
                            <SpanContainer data-tip={findTooltipForSegment(item)}>
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '-3px',
                                  marginTop: '0px',
                                  paddingTop: '0px',
                                }}
                              >
                                <AiOutlineInfoCircle
                                  color="rgba(26, 26, 26, 0.4)"
                                  fontSize="16px"
                                />
                              </div>
                            </SpanContainer>
                          )}
                        </TableSubTitle>
                      </td>
                      <td colSpan={10} />
                    </tr>
                  )}
                  {filteredCompanies
                    .filter(c => c.Segment === item)
                    .map(row => {
                      return (
                        <tr key={row.Company}>
                          {Object.keys(row).map(k => {
                            switch (k) {
                              case 'Company':
                                return (
                                  <td style={{ position: 'sticky', left: 0, zIndex: 3 }}>
                                    {useImageList.includes(row[k]) ? (
                                      <ImageContainer>
                                        <img
                                          src={require(`../../../content/img/research/logos/${row[k]}.jpg`)}
                                          alt={row[k]}
                                        />
                                      </ImageContainer>
                                    ) : (
                                      <ImageReplacementText>{row[k]}</ImageReplacementText>
                                    )}
                                    {/* {row[k]} */}
                                  </td>
                                );
                              case 'CEO / founders':
                                // Check to see if url exists
                                if (linkedin_urls[row[k]]) {
                                  return (
                                    <td>
                                      <TdAnchor href={linkedin_urls[row[k]]} target="_blank">
                                        {row[k]}
                                      </TdAnchor>
                                    </td>
                                  );
                                }

                                // If it doesn't just render the normal text
                                return <td>{row[k]}</td>;
                              case 'Segment':
                                return <td id="secondColumn">{row[k]}</td>;
                              case 'Total Raised':
                              case 'Last Valuation':
                                const num = row[k];
                                if (!num) {
                                  return <td />;
                                }

                                return (
                                  <td>
                                    {`$${num.toLocaleString('en', {
                                      minimumIntegerDigits: 1,
                                    })}B`}
                                  </td>
                                );
                              case 'Revenue*':
                                const revnum = row[k];
                                if (!revnum) {
                                  return <td />;
                                }

                                return <td>{`$${revnum.toLocaleString('en')}`}</td>;
                              case 'Description':
                              case 'Key Investors':
                                return (
                                  <td>
                                    <WrappedText showMore={showMore} id="Description" maxW="250px">
                                      {row[k]}
                                    </WrappedText>{' '}
                                    {row[k].length > 35 && (
                                      <MoreButton showMore={showMore} id="Description" />
                                    )}
                                  </td>
                                );
                              case 'Key Investors':
                              default:
                                return <td>{row[k]}</td>;
                            }
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ position: 'sticky', left: '0' }}>
                  <TableSubTitle>No result</TableSubTitle>
                </td>
                <td colSpan={7} />
              </tr>
            )}
          </table>
        </ResearchCompaniesDiv>
      </ResearchInvestorsFullDiv>
      <CompaniesTableFootnote>*Include estimates from the Base10 team</CompaniesTableFootnote>
      {/* <CompaniesTableFootnote>
        * &nbsp;
        <AnimatedSpan fontSize="12px" paddingBottom="2px" underlineHeight="1px">
          <span onClick={toggleModal}>Click here</span>
        </AnimatedSpan>{' '}
        to view a selection of these companies in market map format.
      </CompaniesTableFootnote> */}
      {/* <CompaniesTableFootnote style={{ marginTop: '50px' }}>
        Sources:{' '}
        <a href="https://www.hcpss.org/f/schools/bsap/hbcu-facts-figures.pdf">
          https://www.hcpss.org/f/schools/bsap/hbcu-facts-figures.pdf
        </a>
        ,{' '}
        <a href="https://uncf.org/the-latest/6-reasons-hbcus-are-more-important-than-ever">
          https://uncf.org/the-latest/6-reasons-hbcus-are-more-important-than-ever
        </a>
        ,{' '}
        <a href="https://nces.ed.gov/fastfacts/display.asp?id=667">
          https://nces.ed.gov/fastfacts/display.asp?id=667
        </a>
        ,{' '}
        <a href="https://tincture.io/why-hbcu-medical-schools-matter-3a810453b04c">
          https://tincture.io/why-hbcu-medical-schools-matter-3a810453b04c
        </a>{' '}
        (1) This is an are illustrative scenario. Funding could vary and returns will fluctuate.
        These illustrative returns should not be relied upon for your own investment decision. The
        fund may or may not invest in the companies shown.
      </CompaniesTableFootnote> */}
      <CompaniesTableFootnoteDiv>
        <DisclaimerTitle>DISCLAIMER</DisclaimerTitle>
        <DisclaimerHeading>CONFIDENTIALITY</DisclaimerHeading>
        <DisclaimerText>
          Each recipient of this web page acknowledges and agrees that the contents hereof
          constitute proprietary and confidential information from which Base10 Partners Management,
          LLC, a Delaware limited liability company (“<u>Base10</u>”), derives independent economic
          value from not being generally known and is the subject of reasonable efforts to maintain
          its secrecy. The recipient further agrees that the contents of this web page are a trade
          secret to Base10. Any reproduction or distribution of this web page, in whole or in part,
          or the disclosure or use of its contents (other than to assess an investor’s investment or
          prospective investment in Base10’s proposed investment vehicle described herein (the “
          <u>Fund</u>”)) without the prior written consent of Base10 is prohibited, and the
          disclosure of this web page or its contents is likely to cause substantial and irreparable
          competitive harm to Base10. By accepting access to this web page, each recipient agrees to
          the foregoing.
        </DisclaimerText>
        <DisclaimerHeading>CERTAIN LEGAL CONSIDERATIONS</DisclaimerHeading>
        <DisclaimerText>
          This overview is intended for informational purposes only; does not constitute investment
          advice or a recommendation, an offer to sell, or the solicitation of an offer to buy; is
          not the basis for any contract to purchase or sell any securities or other instruments in
          any jurisdiction; and should not provide the basis for any investment decision. Further,
          the proposed structure and terms of the Fund may be changed at any time by Base10 and
          should not be viewed as final or complete. Subscriptions for the Fund, if actualized, will
          only be made and accepted on the basis of the final documentation provided to the
          recipient by Base10 or its affiliates. Such securities subsequently offered by Base10 or
          its affiliates, if any, (i) will not be offered or sold in any jurisdiction or to any
          person to whom it is unlawful to make such an offer in such jurisdiction and (ii) will be
          offered only to investors who meet certain suitability requirements and other investment
          qualifications. Such offers will be made in writing and will be subject to the final
          governing documents, the terms of which shall control to the extent they are inconsistent
          with the information set forth herein.
        </DisclaimerText>
        <DisclaimerText>
          Certain information contained in this web page constitutes forward-looking statements,
          which can be identified by the use of forward-looking terminology such as “may”, “will”,
          “should”, “expect”, “anticipate”, “target”, “project”, “estimate”, “intend”, “continue” or
          “believe”, or other variations thereon or comparable terminology. Due to various risks and
          uncertainties, actual results or performance of the fund may differ materially from the
          results or performance reflected or contemplated in such forward-looking statements. The
          forward-looking statements contained herein are made as of the date of this web page, and
          Base10 does not assume the obligation to update any forward-looking statements. Past
          performance is not necessarily indicative of future results.
        </DisclaimerText>
        <DisclaimerHeading>CERTAIN RISK FACTORS</DisclaimerHeading>
        <DisclaimerText>
          The Fund’s investment strategy and structure is novel. An investment in the Fund, if
          actualized, is speculative and entails a high degree of risk. There can be no assurance
          that the Fund will be actualized, or if it does, that its investment objectives will be
          achieved and investors will not incur losses. Moreover, an investment in the Fund, if
          actualized, will provide limited liquidity since the interests therein will not be freely
          transferable, and the investors in the Fund will have very limited withdrawal rights. An
          investor in the Fund must be prepared to bear the risk of an investment in the Fund for an
          indefinite period and be able to withstand a total loss of its investment.
        </DisclaimerText>
      </CompaniesTableFootnoteDiv>
    </ShopifyInsightsContainer>
  );
};

const ImageReplacementText = styled(AppText)`
  margin-left: 5px;
  color: ${theme.BLACK_COLOR};
`;

const SummaryStatsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${laptopSize} {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-gap: 20px;
  }
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const SummaryStatsNumber = styled(AppText)`
  color: ${theme.PRIMARY_COLOR};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const SummaryStatsTitle = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  opacity: 0.8,
  font-size: 18px;
  margin-top: 30px;
  @media ${laptopSize} {
    margin-top: 0px;
  }
  @media ${mobileSize} {
    margin-top: 20px;
  }
`;

const AdvancementTitle = styled(AppTitle)`
  color: ${theme.BLACK_COLOR};
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
`;

const BarChartTitle = styled(AppTitle)`
  color: ${theme.BLACK_COLOR};
  font-size: 18px;
  font-weight: bold;
  margin-top: 40px;
`;

const AdvancementText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 16px;
  margin-bottom: 40px;
  opacity: 0.7;
`;

const CompaniesTableFootnoteDiv = styled.div`
  margin-top: 50px;
`;

// const DisclaimerText = styled(AppText)`
//   font-size: 10px;
//   color: ${theme.BLACK_COLOR};
//   line-height: 20px;
// `;

const DisclaimerHeading = styled(AppTitle)`
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  margin-top: 15px;
  color: rgba(26, 26, 26, 0.5);
`;

const DisclaimerTitle = styled(AppTitle)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: underline;
  color: rgba(26, 26, 26, 0.5);
`;

const ThirdPointNormalText = styled(AppText)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  margin-top: 10px;
`;

const ThirdPointBlueText = styled(AppText)`
  max-width: 120px;
  font-size: 14px;
  color: ${theme.PRIMARY_COLOR};
  margin-top: 10px;
`;

const ThirdPointBigBlueText = styled(AppText)`
  font-size: 30px;
  color: ${theme.PRIMARY_COLOR};
  margin-top: 10px;
`;

const ThirdPointTwoSegments = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
`;

const ThirdPointHeaderText = styled(AppText)`
  margin-top: 20px;
  max-width: 225px;
  text-transform: uppercase;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 10px;
`;

const ThirdPointDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ThirdPointHeader = styled.div`
  width: 80%;
  border-bottom: 1px solid ${theme.BLACK_COLOR};
`;

const FirstPointDiv = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
`;

const FirstPointLabel = styled(AppText)`
  display: inline-block;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${theme.BLACK_COLOR};
  line-height: 30px;
  margin-right: 12px;
  vertical-align: baseline;
`;

const FirstPointNumber = styled(AppText)`
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${theme.PRIMARY_COLOR};
`;

const ThreePartsDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  @media ${mobileSize} {
    grid-template-columns: 1fr;
  }
`;

const BlueSpan = styled.span`
  color: ${theme.PRIMARY_COLOR};
  font-weight: bold;
`;

const EmailSubmitButton = styled.button`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.BLACK_COLOR};
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 4px;
  min-width: 80px;
  height: 26px;
`;

const EmailTextInput = styled.input`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  line-height: 14px;
  color: rgba(26, 26, 26, 0.8);
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 15px 5px 20px;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmailForm = styled.form`
  display: grid;
  grid-template-columns: 150px 200px;
  max-width: fit-content;
  margin-bottom: 28px;
  background-color: rgba(26, 26, 26, 0.2);
  padding: 5px;
  border-radius: 4px;
  @media ${mobileSize} {
    max-width: 100%;
    margin-top: 10px;
    grid-template-columns: 100px 1fr;
  }
`;

const CompaniesTableFootnote = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 12px;
  font-style: italic;
  margin-bottom: 21px;
  line-height: 1.57;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 0;
`;

const KeyInsightsFootnote = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 12px;
  font-style: italic;
  margin-bottom: 21px;
  line-height: 1.57;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

const TdAnchor = styled.a`
  display: inline;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  border-bottom: 1px solid ${theme.PRIMARY_COLOR};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TdText = styled(AppText)`
  display: inline;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FlexTablesContainer = styled.div`
  max-width: 1400px;
`;

interface WrappedTextProps {
  showMore: string[];
  maxW?: string;
  id: string;
}

const WrappedText = styled.div<WrappedTextProps>`
  border: 0;
  font-family: ${theme.PRIMARY_FONT};
  display: inline-block;
  font-size: 14px;
  line-height: 1.57;
  height: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  min-width: ${props => props.maxW ?? '50px'};
  max-width: ${props => (props.showMore.includes(props.id) ? '1000px' : '100px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
`;

const ResearchInvestorsFullDiv = styled.div`
  max-width: 1400px;
`;

const FlexTableDiv = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const ResearchInvestorsHalfDiv = styled.div`
  max-width: 650px;
  width: 45vw;
  justify-self: center;
  @media ${mobileSize} {
    width: 90vw;
  }
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

const MoreButtonStyle = styled.button`
  padding: 0;
  margin: 0;
  padding-left: 5px;
  border: none;
  background: transparent;
  color: ${theme.PRIMARY_COLOR};
`;

const FullWidthItem = styled.div`
  width: max-content;
  display: block;
  white-space: nowrap;
`;

const FoundersBlock = styled.div`
  display: inline;
  white-space: nowrap;
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const FilterByLabel = styled.div`
  opacity: 0.6;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  margin-right: 8px;
  margin-left: 14px;
`;

const FitlerSelect = styled.select`
  background: transparent;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  border-bottom: 1px solid ${theme.PRIMARY_COLOR};
  margin: 0px;
  margin-right: 8px;
  max-width: 150px;
  text-overflow: ellipsis;
  option {
    font-family: ${theme.PRIMARY_FONT};
  }
  @media ${mobileSize} {
    margin: 5px;
  }
`;

const FiltersList = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;

const DisclaimerDiv = styled.div`
  margin-top: 23px;
  background-color: ${theme.WHITE_COLOR};
  padding: 13px;
`;

const DisclaimerText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  /* text-align: center; */
  color: rgba(26, 26, 26, 0.4);
  /* max-width: 1000px; */
  margin: auto auto;
`;

const TableSubTitle = styled.div`
  font-family: ${theme.SECONDARY_FONT};
  margin: 27px 0px 8px 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.33px;
  color: ${theme.BLACK_COLOR};
  text-transform: uppercase;
  acronym {
    background: black;
    color: white;
  }
`;

const ResearchCompaniesDiv = styled.div`
  max-width: 95vw;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  height: 500px;
  th {
    font-family: ${theme.SECONDARY_FONT};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.67px;
    color: rgba(26, 26, 26, 0.6);
    text-align: left;
    /* border-bottom: 1px solid ${theme.BLACK_COLOR}; */
    /* border-right: 8px solid ${theme.OFF_WHITE_BACKGROUND}; */
    padding: 8px 12px 8px 0px;
    vertical-align: bottom;
    background-clip: padding-box;
    border-collapse: collapse;
    position: sticky;
    top: 0;
    background-color: ${theme.OFF_WHITE_BACKGROUND};
    z-index: 3;
  }
  th:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    height: 1px;
    width: calc(100% - 8px);
    margin-top: 0px;
    background-color: ${theme.BLACK_COLOR};
  }
  table {
    width: 100%;
    position: relative;
    border-collapse: separate;
  }
  td {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    border-bottom: 1px solid rgb(203, 204, 205);
    padding-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-clip: padding-box;
    background-color: ${theme.OFF_WHITE_BACKGROUND};
    @media ${mobileSize} {
      line-height: 1.1;
    }
  }
  img {
    max-height: 18px;
    padding: 0;
  }
  #secondColumn {
    position: sticky;
    left: 100px;
    z-index: 3; 
    padding-right: 12px;
  }
  #secondColHead {
    position: sticky; 
    left: 100px; 
    z-index: 4;
  }
  @media ${laptopSize} {
    max-width: 90vw;
    overflow: auto;
    #secondColumn {
      position: relative;
      left: auto;
      z-index: 1; 
      padding-right: 12px;
    }
    #secondColHead {
      position: sticky;
      top: 0;
      left: auto; 
      z-index: 3;
    }
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
  }
`;

const ResearchInvestorsDiv = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  th {
    opacity: 0.6;
    font-family: ${theme.SECONDARY_FONT};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.67px;
    color: ${theme.BLACK_COLOR};
    text-align: left;
    border-bottom: 1px solid ${theme.BLACK_COLOR};
    border-right: 8px solid ${theme.OFF_WHITE_BACKGROUND};
    border-left: 0px;
    border-collapse: collapse;
    padding: 8px 4px 8px 0px;
    vertical-align: bottom;
  }
  table {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
  }
  td {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    border-bottom: 1px solid rgba(26, 26, 26, 0.2);
    border-collapse: collapse;
    padding-left: 4px;
    padding-right: 12px;
    @media ${mobileSize} {
      line-height: 1.1;
    }
  }
  img {
    max-width: 50px;
    max-height: 24px;
    margin: 5px;
  }
  @media ${laptopSize} {
    max-width: 90vw;
    overflow: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
  }
`;

const ResearchInvestorsTitle = styled.div`
  margin-top: 31px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-top: 10px;
  }
`;

const KeyInsightsListItems = styled.div`
  ol {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    list-style: none;
    counter-reset: li;
    margin: 0;
  }
  ol li::before {
    content: counter(li);
    color: ${theme.WHITE_COLOR};
    font-family: ${theme.PRIMARY_FONT};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    text-align: left;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    margin-top: 2px;
    margin-left: -31px;
  }
  ol li:nth-child(3n + 1):before {
    background-color: ${listColors[0]};
  }
  ol li:nth-child(3n + 2):before {
    background-color: ${listColors[1]};
  }
  ol li:nth-child(3n + 3):before {
    background-color: ${listColors[2]};
  }
  ol li {
    counter-increment: li;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    line-height: 20px;
    margin-bottom: 8px;
    /* padding-left: 5px; */
    list-style-position: outside;
    vertical-align: bottom;
  }
`;

const KeyInsightsListTitle = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 8px;
`;

interface SummaryTitleProps {
  bold?: boolean;
}

const SummaryTitleText = styled(AppText)<SummaryTitleProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${props => (props.bold ? '16px' : '13px')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${theme.BLACK_COLOR};
  display: inline;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  opacity: ${props => (props.bold ? '1' : '0.6')};
`;

const SummaryTitle = styled.div`
  padding-bottom: 10px;
`;

const ShopifyInsightsContainer = styled.div`
  padding: 130px 25px 100px 25px;
  max-width: 1400px;
  margin: auto auto;
  background-color: ${theme.OFF_WHITE_BACKGROUND};
  font-family: ${theme.PRIMARY_FONT};
  @media ${mobileSize} {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const ImageContainer = styled.div`
  height: 18px;
  width: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-top: 3px;
`;

const GraphsDiv = styled.div`
  max-width: 95vw;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  align-items: center;
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
  }
`;

export { Research2Segment };
