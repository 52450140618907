import * as React from 'react';
import theme from '../../util/theme';
import styled from 'styled-components';
import { VictoryBar, VictoryChart, VictoryLabel, VictoryAxis } from 'victory';
import { laptopSize, mobileSize } from '../../util/variables';

interface IHistogramProps {
  title: string;
  xAxis: string;
  yAxis: string;
  data: any;
  /**
   * Optional label for x axis
   */
  xLabel?: string;
  /**
   * Optional label for y axis
   */
  yLabel?: string;
  /**
   * Optional Unit to place on the label, such as B, mm, etc
   */
  labelUnit?: string;
}

/**
 * Uses Victory.js to render a histogram.
 *
 * @param title - title of histogram
 * @param xAxis - prop name of x axis. Must be a property on data
 * @param yAxis - prop name of y axis. Must be a property on data
 * @param data - array of data to be rendered
 */

const ChartBarCustomLabel: React.FunctionComponent = ({ ...props }) => {
  const { x, y, datum, text } = props;
  return (
    <g style={{ pointerEvents: 'none', width: 200 }}>
      <foreignObject x={x - 120} y={y - 10} width="120" height="30">
        <img
          style={{ maxWidth: '100px', maxHeight: '20px', float: 'right' }}
          src={require(`../../content/img/research/logos/${text}.png`)}
          alt={text}
        />
      </foreignObject>
    </g>
  );
};

const HorizontalBar: React.FunctionComponent<IHistogramProps> = ({
  title,
  xAxis,
  yAxis,
  xLabel,
  yLabel,
  data,
  labelUnit = '',
}) => {
  return (
    <Div>
      <FilterHeadingLabel>{title}</FilterHeadingLabel>
      <AmountOfFundingChartDiv>
        <VictoryChart domainPadding={30} height={600} width={900} padding={{ left: 120, top: 50, right: 10, bottom: 50 }}>
          <svg>
            <defs>
              <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="rgba(69, 174, 235, 0.3)" />
                <stop offset="100%" stopColor={theme.PRIMARY_COLOR} />
              </linearGradient>
            </defs>
          </svg>
          <VictoryBar
            horizontal
            padding={{ left: 50 }}
            sortKey="% of Fund"
            sortOrder="ascending"
            labels={({ datum }) => `${datum[yAxis]}${labelUnit}%`}
            data={data}
            barRatio={0.8}
            style={{
              data: {
                fill: 'url(#myGradient)',
              },
            }}
            labelComponent={
              <VictoryLabel
                dx={-25}
                style={[
                  {
                    fontSize: 12,
                    fontFamily: theme.PRIMARY_FONT,
                    fontWeight: 'bold',
                    fill: theme.WHITE_COLOR,
                  },
                ]}
              />
            }
            alignment="middle"
            x={xAxis}
            y={yAxis}
            // domain={{x: [2013, 2020], y: [0, 90]}}
          />
          <VictoryAxis
            dependentAxis
            label={yLabel}
            style={{
              tickLabels: {
                fontFamily: theme.SECONDARY_FONT,
                letterSpacing: '1.67px',
                fontSize: '10px',
                color: 'rgb(26, 26, 26, 0.6)',
                opacity: '0.6',
              },
              axisLabel: { fontFamily: theme.PRIMARY_FONT, padding: 36 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'rgb(26, 26, 26, 0.2)', strokeWidth: 0.5 },
            }}
            tickFormat={t => `${t}%`}
          />
          <VictoryAxis
            label={xLabel}
            tickFormat={x => x}
            tickLabelComponent={<ChartBarCustomLabel />}
            style={{
              tickLabels: {
                fontFamily: theme.SECONDARY_FONT,
                fontSize: '10px',
                color: 'rgb(26, 26, 26)',
                opacity: '0.6',
              },
              axisLabel: { fontFamily: theme.PRIMARY_FONT, padding: 130 },
              axis: { stroke: theme.BLACK_COLOR, opacity: '0.2' },
            }}
          />
        </VictoryChart>
      </AmountOfFundingChartDiv>
    </Div>
  );
};

const Div = styled.div``;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const AmountOfFundingChartDiv = styled.div`
  margin: 0;
  padding: 0;
  margin-top: -30px;
  width: 900px;
  max-width: 65vw;
  margin-left: 10px;
  @media ${laptopSize} {
    margin-top: -40px;
    max-width: 80vw;
    margin: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
    width: 100%;
    justify-content: center;
  }
`;

export { HorizontalBar };
