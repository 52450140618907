import styled from 'styled-components';
import Img from 'gatsby-image';
import IndexLayout from '../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle, NavBar, TextCycler } from '../components/ui';
import { Link, graphql } from 'gatsby';
import { BottomNavSegment, Footer, OurShopifyInsightsSegment } from '../components/PageSegments';
import favicon from '../content/img/icon/favicon.ico';
import { Research2Segment } from '../components/PageSegments/research2/Research2main';
import { StringParam, useQueryParam } from 'use-query-params';
import { useEffect, useState } from 'react';

const ShopifyPage = (props: any) => {
  const [hbcu, setHbcu] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log('props: ', props);
    const { location } = props;
    const { search } = location;
    const siteParams = new URLSearchParams(search);
    if (siteParams.has('aidr')) {
      setHbcu(true);
    }

    setLoading(false);
  }, []);
  // const [hbcu, _] = useQueryParam('hbcu', StringParam);

  return (
    <IndexLayout>
      <Helmet>
        <title>Summary of the Advancement Initiative</title>
        <link rel="icon" href={favicon} />
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <meta charSet="UTF-8" />
        <link rel="canonical" href="https://base10.vc/research-advancement-initiative/" />
        <meta name="og:url" content="https://base10.vc/research-advancement-initiative/" />
        <meta name="og:title" content="Summary of the Advancement Initiative" />
        {/* <meta
          name="og:image"
          content="https://res.cloudinary.com/base10/image/upload/v1605315505/advancement-initiative-market-map_zqowza.jpg"
        /> */}
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="og:description" content="Summary of the Advancement Initiative" />
        <meta name="description" content="Summary of the Advancement Initiative" />
        <meta name="twitter:title" content="Summary of the Advancement Initiative" />
        <meta name="twitter:description" content="Summary of the Advancement Initiative" />
        {/* <meta
          name="twitter:image"
          content="https://res.cloudinary.com/base10/image/upload/v1605315505/advancement-initiative-market-map_zqowza.jpg"
        /> */}
        <meta name="twitter:image:alt" content="Summary of the Advancement Initiative" />
        <meta name="twitter:site" content="@Base10Partners" />
        <meta name="twitter:creator" content="@Base10Partners" />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Main>
        {loading ? (
          <div />
        ) : (
          <>
            <NavBar
              key={`${Boolean(hbcu)}`}
              hbcu={Boolean(hbcu)}
              invert={!hbcu}
              initiallyBlack={Boolean(hbcu)}
            />
            <Research2Segment hbcu={Boolean(hbcu)} />
          </>
        )}
        <Footer />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* height: 2000px; */
  /* background: grey; */
`;

export default ShopifyPage;
