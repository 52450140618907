/**
 * Dictionary of linkedin urls
 * Key is founder's name
 * Value is url
 */
export const linkedin_urls: any = {
  'Brian Chesky': 'https://linkedin.com/in/brianchesky',
  'Howie Liu': 'https://www.linkedin.com/in/howieliu/',
  'Sajith Wickramasekara': 'http://linkedin.com/in/sajithw',
  'Mark Ghermezian': 'https://www.linkedin.com/in/markgher/',
  'Henrique Dubugras': 'https://www.linkedin.com/in/henriquedubugras/',
  'Tope Awotona': 'https://www.linkedin.com/in/bawotona/',
  'Melanie Perkins': 'https://www.linkedin.com/in/melanieperkins/',
  'Henry Ward': 'https://www.linkedin.com/in/heward/',
  'Guillaume Pousaz': 'https://www.linkedin.com/in/guillaumepousaz/',
  'Daniel Yanisse': 'https://www.linkedin.com/in/yanisse/?locale=en_US',
  'Brian Armstrong': 'https://www.linkedin.com/in/barmstrong/',
  'Jay Kreps': 'https://www.linkedin.com/in/jaykreps/',
  'Ali Ghodsi': 'https://www.linkedin.com/in/alighodsi/',
  'Jason Citron': 'https://www.linkedin.com/in/jasoncitron/',
  'Tony Xu': 'https://www.linkedin.com/in/xutony/',
  'Dylan Field': 'https://www.linkedin.com/in/dylanfield/',
  'George Fraser': 'https://www.linkedin.com/in/george-fraser-a0219230/',
  'Girish Mathrubootham': 'https://www.linkedin.com/in/girish1/',
  'Sytse Sijbrandij': 'https://www.linkedin.com/in/sijbrandij/',
  'Amit Bendov': 'https://www.linkedin.com/in/amitbendov/',
  'Dave McJannet': 'https://www.linkedin.com/in/dmcjannet/',
  'Johnny Boufarhat': 'https://www.linkedin.com/in/johnny-boufarhat-09967714a/',
  'Apoorva Mehta': 'https://www.linkedin.com/in/apoorvamehta/',
  'Andrew Bialecki': 'https://www.linkedin.com/in/bialecki/',
  'Robert Vis': 'https://www.linkedin.com/in/robertvis/',
  'Andrey Khusid': 'https://www.linkedin.com/in/khusid/',
  'Valentin Stalf': 'https://www.linkedin.com/in/valentinstalf/',
  'Artem Petakov': 'https://www.linkedin.com/in/artem-petakov-745a62b/',
  'Ivan Zhao': 'https://www.linkedin.com/in/ivan-zhao-41a41124/',
  'David Velez': 'https://www.linkedin.com/in/david-v%C3%A9lez-1004875/',
  'Kabir Barday': 'https://www.linkedin.com/in/kbarday/',
  'Eric Rea': 'https://www.linkedin.com/in/ericrea/',
  'Tooey Courtemanche': 'https://www.linkedin.com/in/tooey/',
  'Simón Borrero': 'https://www.linkedin.com/in/simonborrero/',
  'Baiju Bhatt': 'https://www.linkedin.com/in/bprafulkumar/',
  'David Baszucki': 'https://www.linkedin.com/in/davidbaszucki/',
  'Sanjit Biswas': 'https://www.linkedin.com/in/sanjitbiswas/',
  'Ara Mahdessian': 'https://www.linkedin.com/in/ara-mahdessian-1116232/',
  'Anthony Casalena': 'https://www.linkedin.com/in/acasalena/',
  'Patrick Collison': 'https://www.linkedin.com/in/patrickcollison/',
  'Tiago Paiva': 'https://www.linkedin.com/in/tiagopaiva/?locale=en_US',
  'Orion Hindawi': 'https://www.linkedin.com/in/orionhindawi/',
  'Daniel Dines': 'https://www.linkedin.com/in/danieldines/',
  'Chris Comparato': 'https://www.linkedin.com/in/chriscomparato/',
  'Bryan Helmig': 'https://www.linkedin.com/in/bryanhelmig/',
  'Filip Kaliszan': 'https://www.linkedin.com/in/kaliszan/',
  'Peter Szulczewski': 'https://www.linkedin.com/in/peter-szulczewski-b711221/',
  'Vlad Magdalin': 'https://www.linkedin.com/in/bryantchou/',
};
