import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { Modal, Wrapper, AppText, AppTitle, AnimatedSpan, AnimatedLinkAnchor } from '../../ui';

interface Props {
  showModal: boolean;
  toggleModal: () => void;
}

const DisclaimerPopupComponent: React.FunctionComponent<Props> = ({ showModal, toggleModal }) => {
  const [passwordGuess, setPasswordGuess] = useState('');
  const [errorText, setErrorText] = useState('');
  return (
    <PaddingDiv1>
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={showModal}
        toggleModal={toggleModal}
        label="Team Portfolio"
      >
        <Wrapper>
          <PaddingDiv2>
            <DisclaimerTitle>DISCLAIMER</DisclaimerTitle>
            <DisclaimerHeading>CONFIDENTIALITY</DisclaimerHeading>
            <DisclaimerText>
              Each recipient of this web page acknowledges and agrees that the contents hereof
              constitute proprietary and confidential information from which Base10 Partners
              Management, LLC, a Delaware limited liability company (“<u>Base10</u>”), derives
              independent economic value from not being generally known and is the subject of
              reasonable efforts to maintain its secrecy. The recipient further agrees that the
              contents of this web page are a trade secret to Base10. Any reproduction or
              distribution of this web page, in whole or in part, or the disclosure or use of its
              contents (other than to assess an investor’s investment or prospective investment in
              Base10’s proposed investment vehicle described herein (the “<u>Fund</u>”)) without the
              prior written consent of Base10 is prohibited, and the disclosure of this web page or
              its contents is likely to cause substantial and irreparable competitive harm to
              Base10. By accepting access to this web page, each recipient agrees to the foregoing.
            </DisclaimerText>
            <DisclaimerHeading>CERTAIN LEGAL CONSIDERATIONS</DisclaimerHeading>
            <DisclaimerText>
              This overview is intended for informational purposes only; does not constitute
              investment advice or a recommendation, an offer to sell, or the solicitation of an
              offer to buy; is not the basis for any contract to purchase or sell any securities or
              other instruments in any jurisdiction; and should not provide the basis for any
              investment decision. Further, the proposed structure and terms of the Fund may be
              changed at any time by Base10 and should not be viewed as final or complete.
              Subscriptions for the Fund, if actualized, will only be made and accepted on the basis
              of the final documentation provided to the recipient by Base10 or its affiliates. Such
              securities subsequently offered by Base10 or its affiliates, if any, (i) will not be
              offered or sold in any jurisdiction or to any person to whom it is unlawful to make
              such an offer in such jurisdiction and (ii) will be offered only to investors who meet
              certain suitability requirements and other investment qualifications. Such offers will
              be made in writing and will be subject to the final governing documents, the terms of
              which shall control to the extent they are inconsistent with the information set forth
              herein.
            </DisclaimerText>
            <DisclaimerText>
              Certain information contained in this web page constitutes forward-looking statements,
              which can be identified by the use of forward-looking terminology such as “may”,
              “will”, “should”, “expect”, “anticipate”, “target”, “project”, “estimate”, “intend”,
              “continue” or “believe”, or other variations thereon or comparable terminology. Due to
              various risks and uncertainties, actual results or performance of the fund may differ
              materially from the results or performance reflected or contemplated in such
              forward-looking statements. The forward-looking statements contained herein are made
              as of the date of this web page, and Base10 does not assume the obligation to update
              any forward-looking statements. Past performance is not necessarily indicative of
              future results.
            </DisclaimerText>
            <DisclaimerHeading>CERTAIN RISK FACTORS</DisclaimerHeading>
            <DisclaimerText>
              The Fund’s investment strategy and structure is novel. An investment in the Fund, if
              actualized, is speculative and entails a high degree of risk. There can be no
              assurance that the Fund will be actualized, or if it does, that its investment
              objectives will be achieved and investors will not incur losses. Moreover, an
              investment in the Fund, if actualized, will provide limited liquidity since the
              interests therein will not be freely transferable, and the investors in the Fund will
              have very limited withdrawal rights. An investor in the Fund must be prepared to bear
              the risk of an investment in the Fund for an indefinite period and be able to
              withstand a total loss of its investment.
            </DisclaimerText>
            <EnterPasswordDiv>
              <EnterPasswordText>Please enter password and accept to continue:</EnterPasswordText>
              <DisclaimerInput
                value={passwordGuess}
                type="password"
                onChange={e => setPasswordGuess(e.target.value)}
              />
              <AcceptButton
                onClick={() => {
                  passwordGuess === 'Base11' || passwordGuess === 'base11'
                    ? toggleModal()
                    : setErrorText('Wrong password!') && setPasswordGuess('');
                }}
              >
                ACCEPT AND CONTINUE
              </AcceptButton>
              {!!errorText && <ErrorText>{errorText}</ErrorText>}
            </EnterPasswordDiv>
          </PaddingDiv2>
        </Wrapper>
      </Modal>
    </PaddingDiv1>
  );
};

const EnterPasswordText = styled(AppText)`
  font-size: 15px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
`;

const EnterPasswordDiv = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 350px;
  grid-gap: 20px;
`;

const ErrorText = styled(AppText)`
  color: ${theme.RED_COLOR};
  font-size: 16px;
`;

const AcceptButton = styled.button`
  height: 40px;
`;

const DisclaimerInput = styled.input`
  height: 40px;
`;

const PaddingDiv2 = styled.div`
  padding: 50px;
  min-height: 100vh;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PaddingDiv1 = styled.div`
  padding: 50px;
`;

const DisclaimerText = styled(AppText)`
  font-size: 10px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
`;

const DisclaimerHeading = styled(AppTitle)`
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: ${theme.BLACK_COLOR};
  margin-top: 15px;
`;

const DisclaimerTitle = styled(AppTitle)`
  font-size: 20px;
  color: ${theme.BLACK_COLOR};
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: underline;
`;

export { DisclaimerPopupComponent };
